<template>
  <div>
    <a-modal
      title="Google Authentication"
      v-model:visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="$emit('close', true)"
      okText="Verify"
      cancelText="Close"
    >
      <div v-if="!isGoogleAuthActivated" class="qr-code-wrapper">
        <p class="qr-code-instruction">
          Please scan this QR code using your Google Authenticator app.
        </p>
        <div class="qr-code-container">
          <img
            v-if="QRImage"
            :src="QRImage"
            alt="QR Code"
            class="qr-code-img"
          />
          <a-spin v-else />
        </div>
      </div>

      <div class="otp-instruction">
        <p v-if="!isGoogleAuthActivated">
          After scanning the QR code, enter the 6-digit OTP from your Google
          Authenticator app below:
        </p>
        <p v-else>
          Enter the 6-digit OTP from your Google Authenticator app below:
        </p>
      </div>

      <OTPInput @update-otp-value="updateOtpValue" :disable-input="isGoogleAuthActivated ? false : !QRImage"/>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import AuthService from '@/services/auth';
import OTPInput from './OTPInput.vue';
import { useToast } from 'vue-toastification';

export default defineComponent({
  components: {
    OTPInput,
  },
  data() {
    return {
      otpValue: '',
      confirmLoading: false,
      QRImage: null,
      visible: true,
      toast: null,
      scanQRCodeMessage:
        'Please scan this QR code from your google authenticator app.',
      googleAuthOtpMessage:
        'Please use OTP from your google authenticator app for verification.',
    };
  },
  computed: {
    ...mapGetters(['isGoogleAuthActivated']),
  },
  async mounted() {
    this.toast = useToast();
    if (this.isGoogleAuthActivated) {
      this.toast.success(this.googleAuthOtpMessage);
    } else {
      await this.fetchQRCode();
      this.toast.success(this.scanQRCodeMessage);
    }
  },
  methods: {
    ...mapActions(['verifyOTP']),
    async fetchQRCode() {
      try {
        const response = await AuthService.getQRCode();
        this.QRImage = URL.createObjectURL(
          new Blob([response[1]], { type: 'image/png' })
        );
      } catch (error) {
        console.error('Error fetching QR code:', error);
      }
    },
    async handleOk() {
      this.confirmLoading = true;
      await this.verifyOTP({
        OTP: this.otpValue,
      });
      this.confirmLoading = false;
    },
    updateOtpValue(otpValue) {
      this.otpValue = otpValue;
    },
  },
});
</script>

<style scoped>
.qr-code-wrapper {
  text-align: center;
  margin-bottom: 20px;
}

.qr-code-instruction {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-code-img {
  width: 200px;
  height: 200px;
  display: block;
  margin: 0 auto;
}

.otp-instruction {
  text-align: center;
  margin-bottom: 20px;
}
</style>
